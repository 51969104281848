@import '../assets/styles/partials/variables';

#incident-mgmt-page {
  color: $blue-black;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: stretch;
  align-content: stretch;

  .left {
    width: 700px;
    padding: 45px;
  }
  .right {
    min-width: 800px;
    max-width: 900px;
    padding: 45px;
  }

  .input-label {
    display: inline-block;
    width: 100%;
  }

  .input-label::first-letter {
    text-transform: capitalize;
  }

  .input-and-label > div {
    width: 100%;
    padding: 3px;
  }

  .input-and-label.systemic-input > div {
    width: 90%;
    padding: 3px;
    margin-left: 15px;
  }

  #tab-controls {
    border-top: solid gray 1px;
    padding: 3px;
    margin-top: 5px;
  }

  #issue-edit-form.issue-edit-horizontal,
  #issue-summary {
    background: white;
    padding: 8px;
    border-radius: 8px;

    #issue-preview {
      padding: 10px;

      #issue-id {
        font-weight: bold;
      }

      #issue-by {
        display: inline-block;
        margin-top: 6px;
      }
    }
  }

  #issue-edit-form > p {
    background: white;
    padding: 8px;
    border-radius: 8px;
  }

  #issue-edit-form .ant-collapse {
    background: white;
  }

  .ant-tabs-tab-btn {
    color: black;
  }

  .action-close-btn,
  .issue-close-btn {
    color: black;
    float: right;
  }

  .action-input-group.status-input-group div {
    width: 100%;
  }

  .action-card {
    border: solid rgb(216, 213, 213) 1px;
    padding: 3px;
    margin-bottom: 3px;
  }

  .action-header {
    border-bottom: solid rgb(216, 213, 213) 1px;
  }

  #issue-finish-btn {
    background-color: rgb(86, 156, 48);
  }

  #tab-controls button {
    margin-right: 5px;
  }

  #fault-details-summary > div {
    width: 50%;
    margin-bottom: 10px;
  }

  #fault-details-summary .fault-details-left {
    float: left;
  }

  #fault-details-summary .fault-details-right {
    float: right;
  }

  #fault-details-summary .section-title {
    display: inline-block;
    width: 100%;
    font-weight: bold;
  }

  #fault-details-summary .fault-problem-code,
  #fault-details-summary .fault-description {
    display: inline-block;
    width: 100%;
  }

  #problem-list {
    font-size: 9pt;
    width: 100%;
  }

  #problem-list,
  #issue-list {
    th {
      background: rgb(240, 240, 240);
    }

    td,
    th {
      padding: 2px;
    }
  }

  #issue-list-header {
    margin: 10px;

    #num-prev-issues {
      display: inline-block;
      width: 85%;
    }
  }

  #issue-list {
    width: 100%;
  }

  .plant {
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      display: inline;
      margin: 0;
      padding: 0;
    }
    button:hover {
      text-decoration: none;
    }
  }

  #issue-description-input {
    width: 85%;
  }

  .action-delete-confirm,
  .issue-delete-confirm {
    float: right;

    .action-close-confirm-btn,
    .issue-close-confirm-btn {
      float: right;
      margin: 0 5px;
      color: black;
      text-decoration: underline;
    }
  }

  .empty-prc-notice {
    color: lightgray;
    font-size: 10pt;
    margin: 10px;
  }

  #issue-possible-root-causes {
    .input-label {
      button {
        margin-right: 5px;
      }
    }
  }
}
